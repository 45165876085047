html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
}




/*---------------------------------------
  CUSTOM TEXT BOX               
-----------------------------------------*/
.custom-text-box {
    background: var(--white-color);
    border-radius: var(--border-radius-medium);
    margin-bottom: 24px;
    padding: 40px;
}

.custom-text-box-image {
    border-radius: var(--border-radius-medium);
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*---------------------------------------
  .Custom-text          
-----------------------------------------*/

.custom-text-box-icon {
    background: var(--section-bg-color);
    border-radius: var(--border-radius-large);
    color: var(--secondary-color);
    font-size: var(--h6-font-size);
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    line-height: 30px;
}


/*---------------------------------------
  .Div img Background      
-----------------------------------------*/

.div-img-bg {
    /*border: 1px solid black;*/
    padding: 10px;
    background-color: lightgray;
}

/*---------------------------------------
  SECTION               
-----------------------------------------*/
.section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
}

.section-bg {
    background-color: var(--section-bg-color);
}

.section-overlay {
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
}

    .section-overlay + .container {
        position: relative;
    }


/* inter-300 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('fonts/inter-v11-latin-200.woff') format('woff'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/inter-v12-latin-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
 

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('fonts/inter-v12-latin-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/inter-v12-latin-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''), url('fonts/inter-v12-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('fonts/inter-v12-latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
